<template>
  <v-menu
    v-model="datepickerOpened"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="280"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :class="[...classes]"
        :label="title"
        readonly
        v-bind="attrs"
        v-on="on"
        v-model="inputDateFormatted"
        @click:clear="clearDateVerify"
        :clearable="clearable"
        :hide-details="hideDetails"
      />
    </template>
    <v-date-picker
      v-model="inputDate"
      @input="datepickerOpened = false;$emit('input', inputDate);"
      first-day-of-week="1"
      :min="min"
      :max="max"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    value: {
      required: true,
    },
    isNew: {
    },
    min: {
      required: false,
      type: String,
      default: '2000-01-01'
    },
    max: {
      required: false,
      type: String,
      default: '2040-01-01'
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      inputDate: this.value,
      datepickerOpened: false
    }
  },

  mounted() {
    if (this.inputDate === null && this.isNew) {
      this.inputDate = new Date().toISOString().substr(0, 10)
    }
  },

  watch: {
    value (date) {
      this.inputDate = date ? date : ''
    }
  },

  methods: {
    clearDateVerify () {
      this.$emit('clear')
    }
  },

  computed: {
    inputDateFormatted: {
      get () {
        const date = this.inputDate ? new Date(this.inputDate) : null

        if (!date) {
          return ''
        }

        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()

        if (month.length < 2) {
          month = '0' + month
        }

        if (day.length < 2) {
          day = '0' + day
        }

        return [day, month, year].join('.')
      },

      set () {
        this.inputDate = ''
      }
    }
  }
}
</script>
